import {
  Button,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import { ConvertToArabicNumbers, renderDate } from "../../../utils";
import { Paper, TableSortLabel } from "@material-ui/core";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
  table: {
    borderSpacing: "8px 4px",
    border: "1px solid #222",
  },

  tableCell: {
    fontSize: 14,
    padding: 5,
    width: 130,
    border: "1px solid #222",

  },
  searchBar: {
    backgroundColor: "#fff",
    width: 260,
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#f3c637",
  },
  "@media print": {
    printIcon: {
      display: "none",
    },
    printHidden: {
      display: "none",
    },
    toolBar: {
      display: "none",
    },
  },
}));
function isWorthy(item) {
  const total = item.totalToolMortage + item.totalLoans + item.totalInvoices;
  return total >= 20;
}
export default function ClientsArrearsTable({ data, handlePrint, headCells }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("partnerName");
  const [nameQuery, setNameQuery] = useState("");
  const [addressQuery, setAddressQuery] = useState("");
  const classes = useStyles();
  const role = localStorage.getItem("role");
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell, index) => (
            <TableCell
              key={index}
              align="center"
              style={{ fontWeight: "bold", padding: 5 }}
              sortDirection={orderBy === headCell.id ? order : false}
              className='text-center'
            >
              {headCell.id ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  return (
    <>
      <Toolbar className={classes.toolBar} style={{ marginTop: 24 }}>
        <Row xs={12} className='p-2'>
          <Col md={5} className='mb-2'>
            <TextField
              placeholder="بحث بالاسم"
              size="small"
              value={nameQuery}
              className='bg-white'
              onChange={(e) => setNameQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <SearchIcon className='text-black' />
                  </InputAdornment>
                ),
              }}
            />
          </Col>

          <Col md={5}>
            <TextField
              placeholder="خط التحصيل"
              size="small"
              value={addressQuery}
              className='bg-white'
              onChange={(e) => setAddressQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Col>
        </Row>
        <div className={classes.printIcon}>
          <Tooltip title="طباعة التقرير">
            <IconButton onClick={handlePrint}>
              <PrintOutlinedIcon className='text-black' />
            </IconButton>
          </Tooltip>
        </div>
      </Toolbar>



      <TableContainer className="p-2" style={{ width: '100%' }} component={Paper}>

        <Table className={classes.table}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={15}
          />
          <TableBody className={classes.tableBody}>
            {stableSort(data, getComparator(order, orderBy))
              .filter((item) => isWorthy(item))
              .filter((item) =>
                item.partnerName ? item.partnerName.includes(nameQuery) : item
              )
              .filter((item) =>
                addressQuery.length > 0
                  ? item.collectingAddressName
                    ? item.collectingAddressName.includes(addressQuery)
                    : null
                  : item
              )
              .map((item) => {
                return (
                  <TableRow key={item.partnerId} className='hover-row' component={Link}
                    align="center"
                    to={
                      role != "محصل"
                        ? `/seller-profile/${item.partnerId}`
                        : `/seller-user-profile/${item.partnerId}`
                    }>
                    <TableCell className={classes.tableCell} style={{ minWidth: 240 }} align="center">
                      {item.partnerName}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {item.collectingAddressName}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {item.lastDepositDate &&
                        ConvertToArabicNumbers(renderDate(item.lastDepositDate))}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {ConvertToArabicNumbers(Math.abs(Math.round(item.totalInvoices)))}{' '}
                      {Math.round(item.totalInvoices) > 0 ? "عليه" : ""}
                      {Math.round(item.totalInvoices) < 0 ? "له" : ""}
                      {item.totalInvoices === 0 ? "صفر" : ""}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {ConvertToArabicNumbers(Math.abs(Math.round(item.totalLoans)))}{' '}
                      {Math.round(item.totalLoans) > 0 ? "عليه" : ""}
                      {Math.round(item.totalLoans) < 0 ? "له" : ""}
                      {item.totalLoans === 0 ? "صفر" : ""}
                    </TableCell>
                    <TableCell onClick={()=>{console.log(item.totalToolMortage)}} className={classes.tableCell} align="center">
                      {ConvertToArabicNumbers(Math.abs(Math.round(item.totalToolMortage)))}{' '}
                      {Math.round(item.totalToolMortage) > 0 ? "عليه" : ""}
                      {Math.round(item.totalToolMortage) < 0 ? "له" : ""}
                      {item.totalToolMortage === 0 ? "صفر" : ""}
                    </TableCell>

                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
