import React, { useState } from "react";

import BuyersArrearsTable from "./components/BuyersArrearsTable";
import { Typography } from "@material-ui/core";
import axios from "axios";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import Alerto from "../../components/UI/toaster";
import { Container } from "react-bootstrap";
import MainHeader from "../../components/mainHeader/MainHeader";

function BuyersArrears() {
  const [buyersArrears, setBuyersArrears] = useState([]);
  const role = localStorage.getItem("role");
  const getBuyersArrears = () => {
    axios
      .get(`/Laters/latter/buyers`)
      .then((res) => {
        let arr = res.data;
        if (role == "محصل") {
          arr = res.data.filter((ele) => ele.isEmployee == false);
        }
        setBuyersArrears(arr);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      getBuyersArrears();
    }

    return () => {
      mounted = false;
    };
  }, []);

  const [headCells, setheadCells] = React.useState([
    { label: "الاسم", id: "partnerName" },
    { label: "خط التحصيل", id: "collectingAddressName" },
    { label: "اخر تحصيل", id: "lastDepositDate" },
    { label: "فواتير بيع", id: "totalInvoices" },
    { label: "سُلف", id: "totalLoans" },
    { label: "رهن", id: "totalToolMortage" },
  ]);

  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "متأخرات  البائعين",
    onBeforePrint: () => {
      setheadCells([
        { label: "الاسم", id: "partnerName" },
        { label: "خط التحصيل", id: "collectingAddressName" },
        { label: "اخر تحصيل", id: "lastDepositDate" },
        { label: "فواتير بيع", id: "totalInvoices" },
        { label: "سُلف", id: "totalLoans" },
        { label: "رهن", id: "totalToolMortage" },
      ]);
    },
  });

  return (
    <div
      className=" w-100  p-0 pb-4 pb-5 position-relative overflow-x-hidden  "
      style={{
        background: "#534d3b",
        marginTop: " -15px !important",
        minHeight: "107vh",
        outline: " 8px solid #534d3b",
      }}
    >
      <div className="my-4">
        <MainHeader title="متأخرات البائعين" />
      </div>
      <Container>
        {buyersArrears.length > 0 ? (
          <div ref={componentRef}>
            <BuyersArrearsTable
              headCells={headCells}
              data={buyersArrears}
              handlePrint={handlePrint}
            />
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography color="textSecondary" variant="h5">
              {" "}
              ﻻ يوجد متأخرات بائعين{" "}
            </Typography>
          </div>
        )}
      </Container>
    </div>
  );
}

export default BuyersArrears;
